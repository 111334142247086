<template>
    <q-dialog 
      ref="dialogVEx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx ">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">

            <template v-if="actionForm === 'remove'">
              <span>Eliminar video</span>
            </template>
            <template v-else-if="actionForm === 'edit'">
              <span>Editar detalles del video </span>

              <template v-if="modalForm.formErrors>0">
                <div class="subtitleAlt subtitleError">
                  Hay elementos que necesitan tu atención
                </div>
              </template>

            </template>
            <template v-else><!-- // add -->
              <span>Subir video</span>

              <template v-if="modalForm.formErrors>0">
                <div class="subtitleAlt subtitleError">
                  Hay elementos que necesitan tu atención
                </div>
              </template>

            </template>

          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->
        <template v-if="actionForm === 'add'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <!-- Contenido del formulario -->

            <!-- PASO 1 -->
            <div v-show="(modalForm.stepForm===0)">

              <div class="subtitleAlt noselect">
                <div>El peso máximo que debe tener el video que cargues es de 50MB.</div>
              </div>

              <q-form
                ref="myFrmVideoPasoA"
                class="canna_forma_zona canna_formZone"
                style=""
              >
                <!-- Subir archivo -->
                <div style="display: flex;flex-direction: row;justify-content: center;">
                  <div>
                      <div :id="videoCfg.inputElZone" class="uploaderFile_main" style="margin: 0px auto;">
                        <div style="display:none;"> <!-- Zona de input q recibe el archivo ya sea x seleccion o por DD -->
                          <input type="file" 
                            :id="videoCfg.inputEl" 
                            :name="videoCfg.inputEl" 
                            :accept="videoCfg.acceptStr" 
                            style="display:none;"
                          ><!-- @change="filex_onChangeFileToUse($event)" -->
                        </div>
                        <div class="ufx_dropfileszone"></div> <!-- Este recibe el drop --> 
                          <div class="uploaderFile_main_contents">
                            <div class="main_title" style="margin-top: 30px;padding: 0px 25px;">
                              <template v-if="(is_Mobile|| $q.screen.lt.md)">
                                Elige un archivo de tu dispositivo.
                              </template>
                              <template v-else>
                                Arrastra y suelta aquí tu video o elige un archivo de tu dispositivo.
                              </template>
                            </div>
                            <div class="zona_button" style="margin-top: 24px; padding: 0px 25px;display: flex;align-items: center;justify-content: center;">
                                <q-btn type="button" class="" color="app_mainBtn" 
                                  unelevated :label="'Elegir archivo'" 
                                  @click.stop="filex_doSelectVideoFile($event)"
                                />
                            </div>
                            <div class="formats_title" style="margin-top: 24px;padding: 0px 25px;">
                                MP4, MOV, WMV o AVI
                            </div>
                          </div>
                      </div>

                      <!-- Zona de errores si se presentan -->
                      <template v-if="videoErrors.zonaFile===true && videoErrors.zonaFile_msg!==''">
                        <div 
                          class="canna_frmMainErrorZone uploaderFile_mainErrorZone spacerRowTop20" 
                          style="display: flex;justify-content: center;align-items: center; margin-bottom:20px;"
                        >
                          <div style="margin-right:10px;">
                            <img src="images/login_warning.png"/>
                          </div>
                          <div style="flex:1;"  v-html="videoErrors.zonaFile_msg"></div>
                        </div>

                      </template>
                  </div>
                </div>
              </q-form>

            </div>

            <!-- PASO 2 -->
            <!-- Zona de Detalles del video  -->
            <div v-show="(modalForm.stepForm===1)">

              <div class="subtitleAlt noselect">
                <div>
                  Definamos los detalles de este archivo de video:<!-- Por favor, indica los detalles de este video: -->
                  <br> <span style="font-weight: 700; color: #4528A4 !important; ">{{frmDataADD.fileName}}</span>
                </div>
              </div>

              <ValidationObserver ref="formVideoAddx" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormVideoAddx"
                  @submit.prevent="handleSubmit(onSubmitAddVideo)"
                  @reset="onResetAddVIdeo"
                  class="canna_forma_zona spacerRowTop32"
                  style=""
                > 
                  <!-- Campo de titulo -->
                  <ValidationProvider
                    tag="div" name="nombreVideo" :immediate="false" 
                    :rules="{ required: true, cannaTextNamesAlt: true, emojiFree: true , min:5, max:50}"
                    v-slot="{ errors, invalid, validated }"
                  >
                    <!-- (modalForm.createMode===0) ? true : false  -->
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;" class="noselect">
                        <div class="fieldLabel text-left" :class="{'fieldError':invalid && validated}">
                          Título del video
                        </div>
                        <div class="requiredLabel text-right" >Obligatorio</div>
                      </div>
                      <q-input
                        filled
                        name="nombreVideo"
                        maxlength="50"
                        v-model="frmData.title"
                        :bottom-slots="true"
                        :dense="true"
                        :label="(frmData.title==='')?'Escribe el título para este video':''"
                        :disabled="modalForm.processing"
                        :error="invalid && validated"
                        :error-message="
                          (errors[0] !==undefined ) ?  
                            (
                              (errors[0].indexOf('al menos') >=0 && errors[0].indexOf('caracteres')>=0) ? 
                                'Por favor, escribe un dato que contenga entre 5 y 50 caracteres.' : errors[0].replace('nombreVideo','Título del video').replace('El campo','')
                            ) 
                            : ''
                        "
                      >
                        <template v-slot:hint>
                          <div>El título que le des a tu video será visto por nuestro equipo administrativo, utiliza un lenguaje apropiado.</div>
                        </template>
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    ref="fldx_categVideo" name="categoria_video" :immediate="false" 
                    tag="div" class=""
                    :rules=" { required:true, oneOf: allowCategorias } " v-slot="{ errors, invalid, validated  }"
                  >
                    <div class="canna_forma_campo_container spacerRowTop40">
                      <input type="hidden" name="categoria_video"  v-model="frmData.categoria">
                      <div class="subtitleAlt noselect">
                        Elige la categoría que mejor describe este video.
                      </div>
                      <q-option-group
                        class="canna_optionGroup_A spacerRowTop24"
                        label=""
                        name="categoria_video"
                        type="radio"
                        :options="categoriasCatalog"
                        :color="'canna_ColorRadio'"
                        v-model="frmData.categoria"
                      />
                    </div>

                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('categoria_video','La categoría del video').replace('El campo','') }}
                      </div>
                    </template>

                  </ValidationProvider>

                  <!-- Zona de botones -->
                  <div class="buttonZone">
                    <q-btn
                      type="submit" class="buttonSave_SE" color="app_mainBtn" unelevated :label="'Subir y guardar'"
                      :disable="((passed || valid))? false : true"
                    />
                  </div>

                </q-form>
              </ValidationObserver>

            </div>

          </q-card-section>

        </template>


        <template v-if="actionForm === 'edit'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <!-- Contenido del formulario -->
              <div class="subtitleAlt noselect">
                <div>
                  Definamos los detalles de este archivo de video:<!-- Por favor, indica los detalles de este video: -->
                  <br> <span style="font-weight: 700; color: #4528A4 !important;">{{videoRecord.nombre_original}}</span>
                </div>
              </div>

              <ValidationObserver ref="formVideox" v-slot="{ handleSubmit }">
                <q-form
                  ref="myFormVideox"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset="onReset"
                  class="canna_forma_zona spacerRowTop32"
                  style=""
                > 

                  <!-- Campo de titulo -->
                  <ValidationProvider
                    :rules="{ required: true, cannaTextNamesAlt: true, emojiFree: true , min:5, max:50}"
                    name="nombreVideo" :immediate="false" v-slot="{ errors, invalid, validated  }"
                  >
                    <!-- (modalForm.createMode===0) ? true : false  -->
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;" class="noselect">
                        <div class="fieldLabel text-left" :class="{'fieldError':invalid && validated}">
                          Título del video
                        </div>
                        <div class="requiredLabel text-right" >Obligatorio</div>
                      </div>
                      <q-input
                        filled
                        name="nombreVideo"
                        v-model="frmData.title"
                        :bottom-slots="true"
                        :dense="true"
                        :label="(frmData.title==='')?'Escribe el título para este video':''"
                        :disabled="modalForm.processing"
                        :error="invalid && validated"
                        :error-message="
                          (errors[0] !==undefined ) ?  
                            (
                              (errors[0].indexOf('al menos') >=0 && errors[0].indexOf('caracteres')>=0) ? 
                                'Por favor, escribe un dato que contenga entre 5 y 50 caracteres.' : errors[0].replace('nombreVideo','Título del video').replace('El campo','')
                            ) 
                            : ''
                        "
                      >
                      <!-- 
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('nombreVideo','Título del video').replace('El campo','') : ''" 
                      -->
                      <template v-slot:hint>
                        <div>El título que le des a tu video será visto por nuestro equipo administrativo, utiliza un lenguaje apropiado.</div>
                      </template>
                      
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <div class="canna_forma_campo_container spacerRowTop40">
                    <div class="subtitleAlt noselect">
                      Elige la categoría que mejor describe este video.
                    </div>

                    <q-option-group
                      class="canna_optionGroup_A spacerRowTop24"
                      label=""
                      name="modoCreacionJobProfile"
                      type="radio"
                      :options="categoriasCatalog"
                      :color="'canna_ColorRadio'"
                      v-model="frmData.categoria"
                    />
                  </div>

                  <!-- Zona de botones -->
                  <div class="buttonZone">
                    <q-btn type="submit" unelevated class="buttonSave_SE" color="app_mainBtn" 
                      :label="'Guardar cambios'" :disabled="modalForm.processing" 
                    />
                  </div>

                </q-form>
              </ValidationObserver>

          </q-card-section>

        </template>

        <template v-if="actionForm === 'remove'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="subtitleAlt noselect">
              <div style="font-weight: 500;">
                Esta acción es permanente y no se puede deshacer.
              </div>
              <div class="spacerRowTop32">
                El siguiente archivo de video será eliminado:<br>
                <span style="font-weight: 500;">{{videoRecord.nombre_original}}</span>
              </div>
            </div>

            <div class="buttonZone spacerRowTop32" style="">
              <q-btn unelevated :label="'Eliminar video'" type="button" class="buttonSave_SE" color="cannaBtnFormAlt" 
                :disabled="modalForm.processing" 
                @click.stop="dox_removeVideo"
              />
            </div>

          </q-card-section>

        </template>

        <!-- Loader de forma -->
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>

      </q-card>
    </q-dialog>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../boot/axios' // APP code 

import logOut_Mixin from '../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../mixins/helperBase.js'

const vimeo_Token = '' + process.env.VIMEO_TOKEN
const vimeo_clientId = '' + process.env.VIMEO_CID
const vimeo_clientSecret = '' + process.env.VIMEO_CSECT
const vimeo_folderId = (process.env.DEV) ? '' + process.env.VIMEO_DEV_FOLDERID :  '' + process.env.VIMEO_PROD_FOLDERID

import Vimeo from "vimeo"; //  Vimeo = require('vimeo').Vimeo;
const Vimeox = Vimeo.Vimeo;

export default {
  name: 'modalvideo_form',
  props: {
    actionForm: { // add, edit, remove
      type: String,
      required: true,
      default: 'add'
    },
    videoRecord:{ // para edicion 
      type: Object,
      default: null 
    }
  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    //helper_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperBase_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      myDifferify: differify, // para control en edicion 
      // -----------------
      initFormFlag: false,
      processingData:false, 
      allowCategorias: [101,102,103],
      categoriasCatalog:[{value: 103, label: 'Entrevista'},{value: 101, label: 'Presentación'}, {value: 102, label: 'Role Play'}],
      alertActionShow:true, 
      alertActionType:0,
      alertActionMsg:'Se envío correctamente un nuevo correo electrónico',
      vimeoKeys:{ 
        token: vimeo_Token,
        clientId:vimeo_clientId,
        clientSecret: vimeo_clientSecret, 
        folderId: vimeo_folderId,
      }, 
      modalForm: {
        show: false,
        formErrors: 0,
        action: 'add', // // add,  edit , remove
        stepForm: 0, // 0 : subir video, 1:Detalles
        processing: false, 
      },
      frmRecord: null,
      frmFile: null, //para agregar 
      frmDataOriginal:{ title: '', categoria: null },
      frmData: { title: '', categoria: null },   
      frmDataADD: { // Para agregar
        title: '', 
        categoria: null, 
        fileValid: false, 
        file: null , 
        fileName: null,
        fileInputVal:null
      },
      frmMainErrors:{
        formVideoAddx: ''
      },
      frmMainRefs:{
        formVideoAddx: [
          {fieldNameAPi: 'catalogo_id', fieldNameForm: 'categoria_video' },
          {fieldNameAPi: 'titulo', fieldNameForm: 'nombreVideo' }
          // {fieldNameAPi: 'url', fieldNameForm: 'telefono' },
          // {fieldNameAPi: 'comentario', fieldNameForm: '' }
        ]
      },
      videoErrors:{
        zonaFile:false,
        zonaFile_msg: ''
      },
      //options_allowDD:true,
      z_initDD_Events:false,
      videoCfg:{
        canDD: true, // To change if is mobile or not 
        inputElZone:'canna_videoFileInput_ZONE',
        inputEl:'canna_videoFileInput',
        fileInputPrefix:'canna_videofile_',
        maxFiles: 1,
        maxFileSize: 52428800, //50mb // 31457280, //30mb  ---- //Size en bytes  //1mb = 1048576   //5mb 5242880
        fileFormats: "mp4,mov,wmv,avi",  // /(.*?)\.(mp4|mov|wmv|avi|flv)$/
        acceptStr: "video/mp4,video/quicktime,video/x-ms-wmv,video/x-msvideo"
        // https://www.freeformatter.com/mime-types-list.html
        // https://help.encoding.com/knowledge-base/article/correct-mime-types-for-serving-video-files/
        // Video Type	Extension	MIME Type
        // Flash	.flv	video/x-flv
        // MPEG-4	.mp4	video/mp4
        // iPhone Index	.m3u8	application/x-mpegURL
        // iPhone Segment	.ts	video/MP2T
        // 3GP Mobile	.3gp	video/3gpp
        // QuickTime	.mov	video/quicktime
        // A/V Interleave	.avi	video/x-msvideo
        // Windows Media	.wmv	video/x-ms-wmv
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_videoFrmShow
      },
      set: function (value) {
        this.$parent.frm_videoFrmShow = value
      }
    },
    parentX () {
      return this.$parent
    },
    mainErrorsTest () {
      if (this.initFormFlag) {
        if (this.$refs.formVideox) {
          return this.$refs.formVideox.errors
        } else {
          return {}
        }
      } else {
        return {}
      }
      return {}
    },
    mainErrors () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formVideox) {
            if (this.$refs.formVideox.errors) {
              var OBJKeys = Object.keys(this.$refs.formVideox.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formVideox.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    },
    mainErrorsAdd () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formVideoAddx) {
            if (this.$refs.formVideoAddx.errors) {
              var OBJKeys = Object.keys(this.$refs.formVideoAddx.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formVideoAddx.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVEx.show()
      } else if (newVal !== oldVal && newVal === false) {
        // Se resetea la info del formulario 
        this.z_initDD_Events = false 
        this.videoErrors.zonaFile_msg = ''
        this.videoErrors.zonaFile = false
        this.frmMainErrors.formVideoAddx =  ''
        this.frmData = {
          title: '',
          categoria: null
        }
        if(this.modalForm.processing === true ){
          this.modalForm.processing = false
        }
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVEx.hide()
      }
      this.$forceUpdate()
    },
    // 'modalForm.stepForm': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.$forceUpdate()
    //   }
    // },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    },
    is_Mobile: function( newValue, oldValue) { 
      if (newValue!== oldValue){ 
        if (newValue==='xs' || newValue==='sm'){ 
          this.videoCfg.canDD=false
        } else { 
          this.videoCfg.canDD=true
        }
      }
    },
    pageSize : function( newValue, oldValue) { 
      if (newValue!== oldValue){ 
        if (newValue==='xs' || newValue==='sm'){ 
          this.videoCfg.canDD=false
        } else { 
          this.videoCfg.canDD=true
        }
      }
    }
  },
  methods: {
    ...requestPack,
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- videoForm -- set Shown Dialog')
      this.frmDataOriginal = { // Aqui se almacenan los datos en edicion (originales para comparacion) //Se resetea por cualquier cosa
          title: '', 
          categoria: null
      }
      // ---------------------
      this.videoErrors.zonaFile_msg = ''
      this.videoErrors.zonaFile = false
      this.frmMainErrors.formVideoAddx =  ''
      //this.modalForm.stepForm = this.modalidad // ETapa o eventos 
      this.modalForm.action = this.actionForm
      //this.modalForm.datesErrors.show = false // Forzar al que el mensaje de fechas no aparezca 
      setTimeout(() => {
        this.onReset()
        // acciones para el  formulario 
        if (this.modalForm.action === 'add') {
          
          this.cmpx_initDragDropEvents()
          this.modalForm.stepForm = 0
          this.modalForm.formErrors = 0
          this.frmDataOriginal = { title: '', categoria: null }
          this.frmData = { title: '', categoria: null }
          this.frmDataADD = { // Para agregar
            title: '', 
            categoria: null,
            fileValid: false,
            file: null ,
            fileName: null,
            fileInputVal:null
          }

        } else if (this.modalForm.action === 'edit') {

          var tmpRec = JSON.parse(JSON.stringify(this.videoRecord))
          this.frmRecord = tmpRec
          // Datos originales
          this.frmDataOriginal.title = tmpRec.titulo 
          this.frmDataOriginal.categoria = tmpRec.catalogo_id
          // Datos de formulario 
          this.frmData.title = tmpRec.titulo 
          this.frmData.categoria = tmpRec.catalogo_id

        }
        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- videoForm -- doClose Finish Dialog')
      this.$emit('doCloseDialog', { newState: false, finish:true })
      this.modalForm.show = false
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- videoForm -- do Close Dialog')
      this.$emit('doCloseDialog', { newState: false })
      //this.modalForm.datesErrors.show = false
      this.modalForm.show = false
    },
    onReset () {
      this.modalForm.formErrors = 0
      // this.modalForm.stepForm = 0
      this.modalForm.baseName = ''
      if (this.$refs.formVideox) {
        this.$refs.formVideox.reset()
      }
      this.$forceUpdate()
    },
    // ---------------
    // ---------------
    onSubmit: async function () {
      this.$cannaDebug('-- videoForm -- onSubmit')
      if (this.mainErrors > 0) {
        return false
      }
      // ------------ Continuamos 
      var test = 11
      if (this.modalForm.action === 'add') { // Edicion de Etapa
        //alert('Guardando')
        return false

      } else if (this.modalForm.action === 'edit') { // Edicion de Etapa

        this.modalForm.processing = true 
        var myConfig = {}
        if (this.frmData.title !== this.frmDataOriginal.title){ 
          myConfig.titulo = this.frmData.title
        }
        if (this.frmData.categoria !== this.frmDataOriginal.categoria){ 
          myConfig.catalogo_id = this.frmData.categoria
        }
        var count = Object.keys(myConfig)
        if (count<=0){ 
          this.modalForm.processing = false
          this.$emit('doCloseDialog', { newState: false })
          return true
        } else { 
          var responseEditEtapa = null
          await this.dox_EditVideo(myConfig).then(response =>{ 
            responseEditEtapa = response
          })
          if (responseEditEtapa === false) { 
            this.modalForm.processing = false
            return false
          } else { // Emit action
            /* responseEditEtapa ==== {
                action: 'edit',
                recId: myRecord.id,
                record: myRecord
              }
            */
            this.modalForm.processing = false
            this.$emit('afterEditDetails', { action:'edit', data: responseEditEtapa })
          }
        }
      } else { 
        return false
      }
      return true
    },
    dox_removeVideo: async function () {
      this.$cannaDebug('-- formEtapasEventos -- do remove Video')
      var test = 11
      // -------------------
      this.modalForm.processing = true 
      // -------------------
      var mxModel = this.getModelRequest('profile_Videos')

      // planStages_n_Events
      var videoID = this.videoRecord.id
      var myConfig = this.createAxiosConfig ('remove', mxModel, { delimiters: [videoID] }, true, null)

      // Inicia el request -------------------
      var reqRes = { action: [] }
      var reqErr = { action: 0 }

      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.action, myConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
         this.modalForm.processing = false 
        return false
      }
      await this._waitRequestProc(500)
      this.modalForm.processing = false 
      var myDataResp = {
        recId: videoID
      }
      this.$emit('afterRemoveVideo', { action:'remove', data: myDataResp })
    },
    dox_EditVideo: async function ( configData ) {
      // En este punto ya todo esta validado 
      this.$cannaDebug('-- formEtapasEventos -- do Save Stage')
      var test = 11
      // -------------------
      this.modalForm.processing = true 
      // -------------------
      var mxModel = this.getModelRequest('profile_Videos')
      // planStages_n_Events
      var videoID = this.videoRecord.id
      var myConfig = this.createAxiosConfig ('patch', mxModel, { delimiters: [videoID], data: configData }, true, null)

      // Inicia el request -------------------
      var reqRes = { action: [], video:[] }
      var reqErr = { action: 0, video:0 }

      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.action, myConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
         this.modalForm.processing = false 
        return false
      }
      await this._waitRequestProc(200) // Dejamos q se actualize en el server bien 

      var newDataCFG = this.createAxiosConfig ('get', mxModel, { delimiters: [videoID], expand:'catalogo' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.video, newDataCFG).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.video[reqRes.video.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.video += 1
        }
      })

      if (reqErr.video > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handleErrorRequest (reqRes.video[0], reqErr.video)
         this.modalForm.processing = false 
        return false
      }
      // Si todo esta bien
      var myRecord = reqRes.video[0].data.data
      return {
        action: 'edit',
        recId: myRecord.id,
        record: myRecord
      }
    },
    onResetAddVIdeo () {

    },
    async onSubmitAddVideo (){ 
      this.$cannaDebug('-- videoForm -- do Save Stage')
      var test = 11
      var me = this
      // Inicia el request -------------------
      var mvidModel = this.getModelRequest('profile_Videos')
      var reqRes = { action: [], video:[], testvideo:[] }
      var reqErr = { action: 0, video:0, testvideo:0 }

      this.modalForm.processing = true 
      var tituloVideo = this.frmData.title  
      var commentCurp = this.getProfileData.curp + '_' 
      var myParamsVideoInit = { "name": tituloVideo , "description": tituloVideo + ' registro en cannav2...' };
      var fldx_categoria = this.frmData.categoria
      var vimeo_folderId = this.vimeoKeys.folderId
      //-------------------
      var myFileEl = document.getElementById('' + this.videoCfg.inputEl)

      var testSize = this.frmDataADD.fileInputVal.size // var testSize = myFileEl.files[0].size
      var myContentFile = this.frmDataADD.fileInputVal // myFileEl.files[0]

      var fileOriginalName = this.frmDataADD.fileName 

      if (fileOriginalName.length > 250){ 
        var tmpfilename = fileOriginalName.substring(fileOriginalName.length - 250)
        fileOriginalName = tmpfilename
      }

      // Request provisional para checar la session 
      var myFcg = this.createAxiosConfig ('get', mvidModel, {page: 1, 'per-page': '1', expand:'catalogo', sort:'-id' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'videoPrueba', reqRes.testvideo, myFcg).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.testvideo[reqRes.testvideo.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.testvideo += 1
        }
      })
      if (reqErr.testvideo > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        if(reqRes.testvideo[0].type!=='errorSession'){
          me.ex_handleErrorRequest (resReqv.testvideo[0], resErrorv.testvideo)
        }
        me.modalForm.processing = false 
        return false 
      }
      //-------------------

      var clientX = new Vimeox(this.vimeoKeys.clientId , this.vimeoKeys.clientSecret , this.vimeoKeys.token);
      await this._waitRequestProc(200) // Dejamos q se actualize en el server bien 

      clientX.upload( //VIMEO CALL (1)
        myContentFile, 
        myParamsVideoInit,
        async function (uri) {
          console.log('File upload completed. Your Vimeo URI is:', uri); 
          var dataUrl = uri.split("/"); 
          var id_new_Videox = dataUrl[(dataUrl.length-1)];
          //-----------------------
          //ya tenemos el ID del video
          //Creamos el Registro de video enla BDD  //VMX001-0001-A001
          var resReqv = { action:[] }
          var resErrorv = { action:0 }
          var myParams = {
            url: 'https://player.vimeo.com/video/' + id_new_Videox ,
            titulo: tituloVideo, // titulo_video
            catalogo_id: fldx_categoria, 
            comentario: commentCurp + '' + id_new_Videox,
            nombre_original: '' + fileOriginalName
          }
          var configAddVideo = me.createAxiosConfig ('post', mvidModel, { data: myParams }, true, null)
          await me.doRequestByPromise(me.$apiV2, 'pibDataAdd', resReqv.action, configAddVideo).then((resx) => {
            var tmpresponseData = resReqv.action[resReqv.action.length - 1]
            if (tmpresponseData.type !== 'success') {
              resErrorv.action += 1
            }
          })
          await me._waitRequestProc(500)
          if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
            test = 55
            me.ex_handleErrorRequest (resReqv.action[0], resErrorv.action)
            me.modalForm.processing = false 
            return false 
          }
          var recordData = resReqv.action[0].data;
          var newIdRegistro =  recordData.data.id;
          //Se hace Patch al Video para actualizar nombre y Descripcion
          myParamsVideoInit = {
            "name": tituloVideo + '-' + newIdRegistro ,
            "description": tituloVideo + '-' + newIdRegistro
          };
          clientX.request( //VIMEO CALL (2)
            {
              method:'PATCH', 
              path: '/videos/' + id_new_Videox, 
              query: myParamsVideoInit
            },
            async function (error, body, status_code, headers) { /*callback*/
              var testPatch = 11
              if (error) {
                me.frmMainErrors.formVideoAddx = 'Error al actualizar los datos (metadata) del video en VIMEO <br> Error:<br>' + error
                me.modalForm.processing = false 
                return false
              }

              await me._waitRequestProc(600);
              //Mover a la carpeta del projecto en vimeo
              //VMX001-0006-A006
              //----------------------------
              clientX.request( //VIMEO CALL (3)
                {
                  method:'PUT', 
                  path: '/me/projects/' + vimeo_folderId + '/videos/' + id_new_Videox, 
                },
                async function (error, body, status_code, headers) { /*callback*/
                  //VMX001-0007-A007
                  if (error) {
                    var msgxErrPut = 'Error al actualizar los datos (mover a carpeta) del video en VIMEO<br>Error:<br>' + error  
                    me.frmMainErrors.formVideoAddx = msgxErrPut
                    me.modalForm.processing = false 
                    return false;
                  }
                  await me._waitRequestProc(600);
                  var msgxStr = 'El video ha sido registrado'
                  me.$q.notify({ type: 'positive', color:'cannaOKAlert', position: 'top', html:true, message: '' + msgxStr})
                  // HAcemos el EMit para cerrar el modal y Recargar la lista de videos ...!
                  me.$emit('afterAddVideo', { action:'addVideo', newVideoId: id_new_Videox, newRecId:newIdRegistro })
                  return true 
                }
              )
            }
           )
        },
        function (bytesUploaded, bytesTotal) {
          var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + '%')
        },
        function (error) {
          me.frmMainErrors.formVideoAddx = ' Error al subir el video a VIMEO, error: + error'
          me.modalForm.processing = false 
          return false 
        }
      )
      // this.modalForm.processing = false 
    },
    // ----------------------------
    // ----------------------------
    // Funciones de Drag and drop 
    cmpx_initDragDropEvents () { 
      if (this.z_initDD_Events ===true ){
        return false
      }
      // A inicializar los DD events para el componente..! 
      var self = this, target = null, events='';
      //-------------
      var mainELName = this.videoCfg.inputElZone // 'pac_photoUploader_' + this.internalId + ''
      var mainInputElName =  this.videoCfg.inputEl // 'pac_fileinputx_' + this.internalId
      //-------------
      var mainEl = document.getElementById(mainELName);
      //-------------
      events='drag,dragstart,dragend,dragover,dragenter,dragleave'.split(',')
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event, function (e){ 
            e.stopPropagation();
            e.preventDefault();
            if (self.videoCfg.canDD===false){ // if (self.hasRecord==true || self.editMode==true){  //Cambios especiales para modo edicion 
              e.dataTransfer.dropEffect = 'none'
            } else { 
              e.dataTransfer.dropEffect = 'copy'
            }
          })
      })
      // Register the events when the file is out or dropped on the dropzone  
      events='dragend,dragleave'.split(',')
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event,function (e) {
              if (target === e.target) {
                if (self.videoCfg.canDD===false){ // if (self.hasRecord==true || self.editMode==true){ // nothing to do
                  console.log('no se hace nada -- mainEl -- dragend,dragleave')
                } else {
                  mainEl.classList.remove('is-dragover')
                }
              }
          })
      })
      //Register the events when the file is over the dropzone
      events='dragover,dragenter'.split(',');
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event,function (e) {
            target = e.target;
            if (self.videoCfg.canDD===false){ // nothing to do  //if (self.hasRecord==true || self.editMode==true){ 
              console.log('no se hace nada -- mainEl -- dragover,dragenter')
            } else {
              mainEl.classList.add('is-dragover')
            }
          });
      })
      // On a file is selected, calls the readFile method. It is allowed to select just one file - we're forcing it here.
      var inputFileEl = document.getElementsByName(mainInputElName)[0]
      inputFileEl.addEventListener('change', function (e) {
        if (this.files && this.files.length) {
            self.filex_onChangeFileToUse (this.files) // self.readFile(this.files);
            // self.prevEditFlag = false; //Se limpia edit flag
        }
      })
      //Register the drop element to the container component
      mainEl.addEventListener('drop', function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        if (self.videoCfg.canDD===false){ // nothing to do
          console.log('no se hace nada -- mainEl -- drop');
        } else {
          mainEl.classList.remove('is-dragover');
          var files = evt.dataTransfer.files;
          // files is a FileList of File objects. List some properties.
          var output = [];
          for (var i = 0, f; f = files[i]; i++) {
            output.push('', escape(f.name), '', f.type || 'n/a', ') - ', 
              f.size, ' bytes, last modified: ',
              f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a','');
          }
          var salidax = '' + output.join('') + '';
          console.log('' + salidax);
          self.filex_onChangeFileToUse (files) // self.readFile(files);
        }
      });

      this.z_initDD_Events = true
    },
    // ----------------------------
    // Funciones para seleccion de FILE (modo add)
    filex_doSelectVideoFile: function( event ){
      this.$cannaDebug('-- videoForm -- do Save Stage')
      var test = 11
      var inputId = this.videoCfg.inputEl;
      document.getElementById(inputId).click();
    },
    filex_onChangeFileToUse: function ( files ){
      var test = 11
      var self = this
      
      if (files.length > 1 || files.length<= 0 ){   //if (files.length > self.options.maxFiles){  
        var baseErrnumStr = 'El número de archivos permitido es invalido, solo se acepta{0} {1} archivo{2}'
        strMsg = baseErrnumStr;
        strMsg = strMsg.replace('{0}', (self.videoCfg.maxFiles==1)?'':'n' ).replace('{1}', self.videoCfg.maxFiles ).replace('{2}', (self.videoCfg.maxFiles==1)?'':'s'); 
        this.videoErrors.zonaFile_msg = strMsg
        this.videoErrors.zonaFile = true
        this.$forceUpdate()
        return false
      } else { 
        this.videoErrors.zonaFile_msg = ''
        this.videoErrors.zonaFile = false
      }
      // var fileDoc =  event.target.files[0]
      var fileDoc =  files[0]
      //-- Se valida la(s) extension(es) de el/los archivos
      var validFormats=true
      var myreg = '(.*?)\.(' + this.videoCfg.fileFormats.replace(/\,/g,'|') + ')$'
      var tReg = new RegExp(myreg)
      var testName = fileDoc.name.toLowerCase()
      var validFormats = tReg.test(testName)
      //validamos peso
      var validFileSize = ( fileDoc.size <= this.videoCfg.maxFileSize ) ? true:false
      var validFilex = false
      //------------------------------
      if (validFormats===false){ // RARO peropuede pasar 
        this.videoErrors.zonaFile_msg = 'Al parecer tu video no tiene el formato permitido'
        this.videoErrors.zonaFile = true
        this.$forceUpdate();
        return false
      } else { 
        validFilex = true
      }

      if (validFileSize===false){ 
        this.videoErrors.zonaFile_msg = 'Al parecer tu video pesa mas de lo permitido'
        this.videoErrors.zonaFile = true
        this.$forceUpdate();
        return false
      }

      if  (validFormats===true && validFileSize===true){ 

        var mainInputElName =  this.videoCfg.inputEl // 'pac_fileinputx_' + this.internalId
        var inputFileEl = document.getElementsByName(mainInputElName)[0]
        if (inputFileEl){ 
          // Establecer el valor del inputFIle
          this.frmDataADD.fileInputVal =  fileDoc

          this.videoErrors.zonaFile_msg = ''
          this.videoErrors.zonaFile = false

          this.frmDataADD.fileValid = true 
          this.frmDataADD.fileName = fileDoc.name
          this.frmDataADD.file
          this.modalForm.stepForm=1

        }

      }

      this.$forceUpdate();
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- videoForm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- videoForm -- created')
    this.$q.iconSet.field.error = 'r_report_problem'
    this.z_initDD_Events = false 
  },
  beforeMount () {
    // this.$cannaDebug('-- videoForm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- videoForm -- mounted')
    if (this.is_Mobile  === true ){ 
      this.videoCfg.canDD = false
    } else {
      this.videoCfg.canDD = true
    }
    this.$forceUpdate()
    //LLENAR los puestos Valido
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- videoForm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- videoForm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- videoForm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- videoForm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- videoForm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- videoForm -- destroyed')
  }
}
</script>
