<template>
  <div id="dash_videos_mainpage" class="dash_video_mainpage canna_pageFlexZone" style="width: 100%;height: calc(100% - 0px);overflow: hidden;">

    <pageHeader
      :showSubtitle="false"
      :showTitleAltZone="false"
      :showTlbZone="false"
      :cls_title_altZone="'pruebaAltZonewwww'"
      class="canna_videoPageHeader"
    >
      <slot>
        <div class="demozzzwww" style="width: calc(100% - 10px);position: relative;">
          <div>
            Videos
          </div>
        </div>
      </slot>
    </pageHeader>

    <div id="dash_docs_header" class="noselect dash_videos_header">
        <div>
          <template v-if="numVideos >= 1">
            {{numVideos}} Video{{(numVideos>1)?'s' : ''}}
          </template>
          <template v-else>
            Ningún video
          </template>
        </div>
        <div>
          <q-btn outline unelevated  
            type="button" class="btnactionElement" color="app_mainBtn" 
            :label="'Subir video'" icon="add" style="background-color:#fff !important;"
            @click.stop="vd_addVideoRecord"
          /><!-- icon-right="add" -->
        </div>
    </div>

    <div id="dash_video_contentPage" class="dash_videos_contentPage">
      <div id="dash_video_contents" class="dash_videos_contents">

        <template v-if="(videoCatalog.length<=0 && zinitData===true )">
          <div>
            <div class="video_nodata_zone">
              <div class="title spacerRowTop48">Aún no has subido ningún video</div>
              <div class="text_subExplainer spacerRowTop4">
                Los videos que subas en esta sección servirán para que <template v-if=" $q.screen.gt.xs"><br></template>
                nuestro equipo te conozca mejor, ¡sube tu primer video!
              </div>
              <div class="nodataImage spacerRowTop48" style="justify-content: center;display: flex;">
                <cxBackground :bgType="'planes_nosearch'" :size="currentSizeNodata" />
              </div>
            </div>
          </div>
        </template>
        
        <template v-if="(videoCatalog.length > 0 && zinitData===true )">
          <div class="dash_videos_container">

            <div 
              v-for="(video, index) in videoCatalog"
              :key="'vdItem_' + index +'_' + video.id" 
              :id="'vdItem_' + index +'_' + video.id"
              class="dash_videoItem" >

              <div class="video_Col">
                <div class="videoBack" style="height:309px; "><!-- la altura es provisional -->

                  <template v-if="video.vimeoInfo.vimeoEstatus=== 'complete'">
                    <div class="canna_video_container" style="position:relative;">
                      <div class="data_video">

                        <template v-if="(video.vimeoInfo.thumb!='' && video.vimeoInfo.imgInfo!=null)">
                          
                          <vimeo-player 
                            :ref="'player_' + video.id" 
                            :video-id="video.vimeoInfo.videoId"
                            :video-url="video.url"
                            :height="'309px'"
                            :width="'100%'"
                            :client-height="'309px'"
                            :client-width="'100%'"
                            :options="vimeoOptions"
                          />
                          <!-- 
                          <img id="videoThumbEtapaB" :src="video.vimeoInfo.thumb" style="max-height: 204px !important;width: 100%;">
                          <img class="ap_video_layer" src="images/img-video-2.png" @click.stop="vd_doVideoPlayerAction(video)">
                          -->

                        </template>

                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="canna_video_container canna_video_container_progressMain" style="position:relative;">
                      <div class="canna_video_container_progress" style="position: relative;margin-bottom: 20px;">
                        <img class="" src="images/video_procesando.png">
                      </div>
                    </div>
                  </template>

                </div>
              </div>

              <div class="info_Col">
                <div class="col_a"><!-- zona de texto --> 
                  <div class="title">
                    {{video.titulo}}
                  </div>
                  <div class="title_b">
                    {{video._embedded.catalogo.nombre}}
                  </div>
                  <div class="title_c">
                    Fecha de subida: {{formatFechaVideo ( video.created_at , 2 )}}
                  </div>
                </div>
                <div class="icon_Col"> <!-- icono -->
                    <cxIcon
                      iconType="moreFramed"
                      size="24px"
                      class="actionIcon"
                      :cls="'icon_svg_overD'"
                      :overCls="'icon_svg_offD'"
                      :state="0"
                      :hasQMenu="true"
                      :hasMouseOver="true"
                      :hasAction="true"
                      :allowDisabled="true"
                      :allowClick="false"
                    >
                      <template v-slot:menu>
                        <q-menu anchor="bottom middle" self="top end">
                          <q-item clickable v-close-popup @click.stop="vd_editVideoDetails(video)">
                            <q-item-section>Editar detalles del video</q-item-section>
                          </q-item>

                          <q-item clickable 
                            :disable="(video.vimeoInfo.vimeoEstatus !== 'complete') ? true : false"
                            v-close-popup @click.stop="vd_removeVideoDetails(video)"
                          >
                            <q-item-section>Eliminar video</q-item-section>
                          </q-item>

                        </q-menu>
                      </template>
                    </cxIcon>
                </div>
              </div>

            </div>

            <template v-if="(showDemoItemEl === true)">
              <div class="dash_videoItem">

                <div class="video_Col">
                  <div class="videoBack" style="height:309px; "><!-- la altura es provisional --> 
                    &nbsp; 
                  </div>
                </div>

                <div class="info_Col">
                  <div class="col_a"><!-- zona de texto --> 
                    <div class="title">
                      Título del video
                    </div>
                    <div class="title_b">
                      Categoría del video
                    </div>
                    <div class="title_c">
                      Fecha de subida:  01, Ene, 2021
                    </div>
                  </div>
                  <div class="icon_Col"> <!-- icono -->
                      <cxIcon
                        iconType="moreFramed"
                        size="24px"
                        class="actionIcon"
                        :cls="'icon_svg_overD'"
                        :overCls="'icon_svg_offD'"
                        :state="0"
                        :hasQMenu="true"
                        :hasMouseOver="true"
                        :hasAction="true"
                        :allowDisabled="true"
                        :allowClick="false"
                      >
                        <template v-slot:menu>
                          <q-menu anchor="bottom middle" self="top end">
                            <q-item clickable v-close-popup @click.stop="testFn">
                              <q-item-section>Editar detalles del video</q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click.stop="testFn">
                              <q-item-section>Eliminar video</q-item-section>
                            </q-item>

                          </q-menu>
                        </template>
                      </cxIcon>
                  </div>
                </div>
                
              </div>
            </template>

          </div>
        </template>

      </div>
    </div>

    <frmVideo
      ref="frmVideox"
      :actionForm="frm_videoAction"
      :videoRecord="frm_videRecord"
      v-on:doCloseDialog="doCloseVideoForm"
      v-on:afterAddVideo="doVideoRecordAction"
      v-on:afterEditDetails="doVideoRecordAction"
      v-on:afterRemoveVideo="doVideoRecordAction"
    >
    </frmVideo>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>

  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import pageHeader from 'components/layout/headerPage'

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../mixins/helperCatsMix.js'

const vimeo_Token = '' + process.env.VIMEO_TOKEN
const vimeo_clientId = '' + process.env.VIMEO_CID
const vimeo_clientSecret = '' + process.env.VIMEO_CSECT
const vimeo_folderId = (process.env.DEV) ? '' + process.env.VIMEO_DEV_FOLDERID :  '' + process.env.VIMEO_PROD_FOLDERID

export default {
  name: 'videos_screen',
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{
    cxIcon: require('components/appIcons.vue').default,
    cxBackground: require('components/appBackgrounds').default,
    frmVideo: require('components/forms/video/videoForm.vue').default,
    pageHeader
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      //-------------
      showDemoItemEl: false, //temporal 
      //-------------
      zinitData:false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false, 
      currentSizeNodata:'300px',
      //------------
      vimeoOptions: {
        responsive: true,
        width: "1000",
        height: "100%",
      },
      vimeoKeys:{ 
        token: vimeo_Token,
        clientId:vimeo_clientId,
        clientSecret: vimeo_clientSecret, 
        folderId: vimeo_folderId,
      }, 
      //------------
      frm_videoFrmShow: false, 
      frm_videoAction: 'add', 
      frm_videRecord: null, 
      //------------
      //numVideos: 8 ,
      videoCatalog:[],
      videoItem: {
        expanded: true,
        estatus: 0
      },
      progress1: 0.3,
      progress2: 0.9
    }
  },
  computed: {
    cat_VideosBase () { // catalogo id = 33 Documentos personales
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(15) : this.getHelperCat(15)
    },
    numVideos () {
      return this.videoCatalog.length
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    testFn () { 
      this.$cannaDebug('-- videoMainScreen -- test')
    },
    test () { 
      this.$cannaDebug('-- videoMainScreen -- test')
    },
    formatFechaVideo ( fechaVal , formatNum ) { 
      var fechaStr = ''
      if(formatNum === undefined || formatNum === null || formatNum === '' || formatNum===1){
        fechaStr = this.$moment(fechaVal, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
      } else { 
        fechaStr = this.$moment(fechaVal, 'YYYY-MM-DD HH:mm:ss').format('DD, MMM, YYYY')
        fechaStr.replace('.','')
      }
      return fechaStr
    },
    doCloseVideoForm (payload) {
      this.$cannaDebug('-- videoMainScreen -- do Close Video Form')
      this.$refs.frmVideox.doHide()
      this.frm_videoFrmShow = false
      this.frm_videoAction = 'add'
      this.frm_videRecord = null
      this.$forceUpdate()
    },
    async doVideoRecordAction (payload) {
      this.$cannaDebug('-- videoMainScreen -- do Video Record Action')
      var test = 11
      this.loading_Process = true
      var myPayload = JSON.parse(JSON.stringify(payload))
      this.$refs.frmVideox.doHide()
      if (myPayload.action === 'edit'){
        // Ajustamos el registro 
        var videoRec = myPayload.data.record
        var currIndex = this.videoCatalog.findIndex(item => item.id=== myPayload.data.recId) // tiene que existir forsozo..! 
        var currVideoREcord = this.videoCatalog[currIndex]

        videoRec.expanded = currVideoREcord.expanded
        videoRec.vimeoInfo = {
          imgInfo: null, 
          vimeoEstatus:'', 
          thumb:'',
          videoId:''
        }
        var respVideoImg = null
        var videoId = this.vd_getVimeoId(videoRec.url)
        videoRec.vimeoInfo.videoId = videoId
        await this.vd_getVimeoImageInfo ( videoId , 2 , videoRec ).then( (resx)=> {
          respVideoImg = resx 
        });
        test = 55
        this.$cannaDebug('-- videoMainScreen -- getVimeoImageInfo / doVideoRecordAction --- ' + respVideoImg)

        this.videoCatalog[currIndex] = videoRec
        this.$forceUpdate()

        await this._waitRequestProc(1000)

        this.loading_Process = false
        return true 

      } else if (myPayload.action === 'remove'){

        var indexRemoved = this.videoCatalog.findIndex(item => item.id=== myPayload.data.recId) // tiene que existir forsozo..! 
        if (indexRemoved>=0){
          this.videoCatalog.splice(indexRemoved, 1)
        }
        this.$forceUpdate()
        await this._waitRequestProc(1000)

        this.loading_Process = false
        return true

      } else if (myPayload.action === 'addVideo'){ 
        var respInitData = null 
        await this.initFormData().then( respx => {
          respInitData = respx 
        })
        this.$cannaDebug('-- docsMainScreen -- init Form Data Finished -- ' + respInitData)
        await this._waitRequestProc(500)
        this.loading_Process = false
        this.$forceUpdate()
      }
      /// me.$emit('afterAddVideo', { action:'addVideo', newVideoId: id_new_Videox, newRecId:newIdRegistro })
      // var myDataResp = {
      //   recId: videoID
      // }
      // this.$emit('afterRemoveVideo', { action:'remove', data: myDataResp })

      // /* responseEditEtapa ==== {
      //     action: 'edit',
      //     recId: myRecord.id,
      //     record: myRecord
      //   }
      // */
      // this.modalForm.processing = false
      // this.$emit('afterEditDetails', { action:'edit', data: responseEditEtapa })
    },
    vd_getVimeoId (urlx){
      if (urlx=='' || urlx==undefined || urlx ==null){ 
        return null 
      } else {
        var regex = new RegExp(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
        var myvideoPieces= regex.exec(urlx);
        var currentID = '' + myvideoPieces[5];
        return currentID
      }
    },
    vd_getVimeoImageInfo:async function( videoIdx , modo , recordX ){
      // modo == // 1 Input de Captura en Modal  // 2 = input de url en Vista de videos  ( se necesita recordX )
        if (this.debug){ console.log('-- etapa2 Detail -- prueba vimeo Image --- '); }
        this.addVideoLoadingVimeo = true;
        var test=11
        var myToken = this.vimeoKeys.token
        var videoId = '';

        var myConfig= { 
          url:'https://api.vimeo.com/videos/' + videoIdx ,
          method: 'get',
          headers:{
            'Content-Type':'application/json', 'Accept':'application/vnd.vimeo.*+json;version=3.4', 'Authorization' : 'bearer ' + myToken
          }
        };
        //------------------------------------
        var actionRequest = [] ;
        await this.$axios(myConfig).then( (response) => {
          actionRequest.push({type:'success', data: response });
        }).catch( (error) => {
          actionRequest.push({type:'error'  , data:error.response });
        });

        if (modo==1){  // 1 Input de Captura en Modal  // WIP 

          if (actionRequest[0].type=='success'){
            this.add_videoInfo.imgInfo =  actionRequest[0].data.data;
          } else { 
            this.add_videoInfo.imgInfo = null; 
          }
          if (actionRequest[0].type=='success'){
            this.add_videoInfo.imgInfo =  actionRequest[0].data.data;
          } else { 
            this.add_videoInfo.imgInfo = null; 
          }
          if (this.add_videoInfo.imgInfo==null){
            this.add_videoInfo.thumb = '';
            return false;
          } else {
            var imgUrl = '';
            if (this.add_videoInfo.imgInfo.hasOwnProperty('pictures')){ 
              if (this.add_videoInfo.imgInfo.pictures.hasOwnProperty('sizes')){ 
                  if (this.add_videoInfo.imgInfo.pictures.sizes.length==10){ 
                    imgUrl = this.add_videoInfo.imgInfo.pictures.sizes[3].link; 
                  } else { 
                    var lnxps = this.add_videoInfo.imgInfo.pictures.sizes.length; 
                    imgUrl = this.add_videoInfo.imgInfo.pictures.sizes[lnxps-1].link;
                  }
              }
            }
            this.add_videoInfo.thumb = imgUrl;
            this.$forceUpdate();
            return true;
          }

        } else {  // 2 = input de url en Vista de videos  ( se necesita recordX )

          if (actionRequest[0].type=='success'){
            recordX.vimeoInfo.imgInfo =  actionRequest[0].data.data;
            recordX.vimeoInfo.vimeoEstatus = actionRequest[0].data.data.transcode.status;
          } else { 
            recordX.vimeoInfo.imgInfo = null;
            recordX.vimeoInfo.vimeoEstatus = '';
          }
          if (recordX.vimeoInfo.imgInfo==null){
            recordX.vimeoInfo.thumb = '';
            return false;
          } else {
            var imgUrl = '';
            if (recordX.vimeoInfo.imgInfo.hasOwnProperty('pictures')){ 
              if (recordX.vimeoInfo.imgInfo.pictures.hasOwnProperty('sizes')){ 
                  if (recordX.vimeoInfo.imgInfo.pictures.sizes.length==10){ 
                    imgUrl = recordX.vimeoInfo.imgInfo.pictures.sizes[3].link; 
                  } else { 
                    var lnxps = recordX.vimeoInfo.imgInfo.pictures.sizes.length; 
                    imgUrl = recordX.vimeoInfo.imgInfo.pictures.sizes[lnxps-1].link;
                  }
              }
            }
            recordX.vimeoInfo.thumb = imgUrl;
            return true;
          }
        }
    },
    //------------
    vd_addVideoRecord () { 
      this.$cannaDebug('-- videoMainScreen -- edit Video Details')
      this.frm_videoAction = 'add'
      this.frm_videRecord = null
      setTimeout(() => {
        this.$refs.frmVideox.doShow()
      }, 200)
    },
    vd_editVideoDetails (videoItem) {
      this.$cannaDebug('-- videoMainScreen -- edit Video Details')
      this.frm_videoAction = 'edit'
      this.frm_videRecord = JSON.parse(JSON.stringify(videoItem))
      setTimeout(() => {
        this.$refs.frmVideox.doShow()
      }, 200)
    },
    vd_removeVideoDetails(videoItem){
      this.$cannaDebug('-- videoMainScreen --  remove Video Details')
      this.frm_videoAction = 'remove'
      this.frm_videRecord = JSON.parse(JSON.stringify(videoItem))
      setTimeout(() => {
        this.$refs.frmVideox.doShow()
      }, 200)
    },
    //------------
    async initFormData () {
      // Inicializamos los datos de Documentos registrados de la persona 
      var test=11
      var mcModel = this.getModelRequest('profile_Videos')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      // profile_Videos:['v2', 'profile/video'] //${secure}://${server}/index.php/api/v2/profile/video?per-page=all&expand=catalogo&sort=-id
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {'per-page': 'all', expand:'catalogo', sort:'-id' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.docsDB_Records = []
        this.zinitData = true
        return false 
      }
      // -----------------------
      var myVideosData = reqRes.action[0].data.data
      var newCatalogVids = []
      if (myVideosData.length>0){

        for (var item of myVideosData ) {
            var newRec = JSON.parse(JSON.stringify(item))
            newRec.expanded = false
            newRec.vimeoInfo = {
              imgInfo: null, 
              vimeoEstatus:'', 
              thumb:'',
              videoId:''
            }
            if (newRec.url!=='' && newRec.url!==null){ 
              var videoId = this.vd_getVimeoId(newRec.url)
              var respVideoImg = null
              newRec.vimeoInfo.videoId = videoId
              await this.vd_getVimeoImageInfo ( videoId , 2 , newRec ).then( (resx)=> {
                respVideoImg = resx 
              });
              test = 55
              this.$cannaDebug('-- videoMainScreen -- getVimeoImageInfo --- ' + respVideoImg)
            }
            newCatalogVids.push(newRec)
        }

      }
      this.videoCatalog = newCatalogVids
      this.zinitData = true
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- videoMainScreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- videoMainScreen -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- videoMainScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- videoMainScreen -- mounted')

    this.zloadingData = true 
    this.zinitData = false

    if (this.$store.state.myapp.helperCatalog.length<=0){ 
      await this.helper_getAllCatalogs().then(respx =>{
        this.$cannaDebug('-- docsMainScreen -- get All Catalogs -- finished ')
      })
      await this._waitRequestProc(1500)
    }

    if (this.getHelperCat(15).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (15, null).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 15 videos finish ')
        testCatA = respx 
      })
    }
    await this._waitRequestProc(500)

    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$cannaDebug('-- docsMainScreen -- init Form Data Finished -- ' + respInitData)

    this.zloadingData = false
    return true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- videoMainScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- videoMainScreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- videoMainScreen -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- videoMainScreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- videoMainScreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- videoMainScreen -- destroyed')
  }
}
</script>
