import { render, staticRenderFns } from "./videoForm.vue?vue&type=template&id=2edbd73e&"
import script from "./videoForm.vue?vue&type=script&lang=js&"
export * from "./videoForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QField from 'quasar/src/components/field/QField.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerOrbit from 'quasar/src/components/spinner/QSpinnerOrbit.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSeparator,QForm,QBtn,QInput,QOptionGroup,QField,QInnerLoading,QSpinnerOrbit});
